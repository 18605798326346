







































import Vue from 'vue';
export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    activeItems: {
      type: Array,
      required: true,
    },
    updateActiveItems: {
      type: Function,
      required: true,
    },
    hideChevronButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get(): string[] {
        return this.activeItems as string[];
      },
      set(val: string[]) {
        this.updateActiveItems(val);
      },
    },
  },
});
