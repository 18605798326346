




















































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import NavMenuItem from '@/components/Presenter/NavMenuItem.vue';
import EmbedModal from '@/components/Presenter/EmbedModal.vue';
import ThemeModel from '@/dtos/theme';
import { formatDateString } from '@/utils/tools';

export default Vue.extend({
  components: {
    NavMenuItem,
    EmbedModal,
  },
  data() {
    return {
      now: new Date(),
      selectedTabItem: 0,
      qrCodeURL: '',
      showEmbedModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      sessionViewers: 'viewer/getSessionViewers',
      sessionTheme: 'viewer/getSessionTheme',
      baseURL: 'auth/getUserBaseURL',
      showTVControls: 'auth/getUserShowTVControls',
      isAdvancedTVOutputEnabled: 'auth/isAdvancedTVOutputEnabled',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    computedReactionsCount(): number {
      let countReactions = 0;
      if (this.currentSession) {
        if ('reactions' in this.currentSession) {
          for (const count of Object.values(this.currentSession.reactions)) {
            countReactions += count as number;
          }
        }
      }
      return countReactions;
    },
    computedActiveUsers(): number {
      if (this.sessionViewers) {
        return this.sessionViewers.length;
      }
      return 0;
    },
    computedDataCollection(): any {
      const labels = [0, 5, 10, 20];
      const datacollection = {
        labels,
        datasets: [
          {
            label: this.$t('views.presenter.session.statsReactions'),
            backgroundColor: 'transparent',
            pointStyle: 'line',
            data: [0, 5, 10, 20],
            borderColor: '#67EDB8',
          },
          {
            label: this.$t('views.presenter.session.statsCoUsers'),
            backgroundColor: 'transparent',
            pointStyle: 'line',
            data: labels.map((i) => 0.5 * Math.sin(i / 5)),
            borderColor: '#80F5FF',
          },
        ],
      };
      return datacollection;
    },
    computedReactions(): any {
      if (this.currentSession && this.currentSession.reactions) {
        const { reactions } = this.currentSession;
        if (this.theme.limitReactions && this.theme.reactions) {
          const sessionReactions = [];
          for (const reactionName of this.theme.reactions) {
            switch (reactionName) {
              case 'thumbs-up':
                sessionReactions.push({
                  name: 'thumbs-up',
                  imageName: 'reaction-thumbs-up.png',
                  color: '#0B30E9',
                  value: reactions.thumbsUpCount,
                  fillLevel:
                    (reactions.thumbsUpCount / this.computedReactionsCount) *
                    100,
                  label: this.$t('views.thumbsUp'),
                });
                break;
              case 'thumbs-down':
                sessionReactions.push({
                  name: 'thumbs-down',
                  imageName: 'reaction-thumbs-down.png',
                  color: '#0660F3',
                  value: reactions.thumbsDownCount,
                  fillLevel:
                    (reactions.thumbsDownCount / this.computedReactionsCount) *
                    100,
                  label: this.$t('views.thumbsDown'),
                });
                break;
              case 'love':
                sessionReactions.push({
                  name: 'love',
                  imageName: 'reaction-love.png',
                  color: '#0092FF',
                  value: reactions.heartCount,
                  fillLevel:
                    (reactions.heartCount / this.computedReactionsCount) * 100,
                  label: this.$t('views.loveIt'),
                });
                break;
              case 'funny':
                sessionReactions.push({
                  name: 'funny',
                  imageName: 'reaction-funny.png',
                  color: '#15A4F0',
                  value: reactions.funEmojiCount,
                  fillLevel:
                    (reactions.funEmojiCount / this.computedReactionsCount) *
                    100,
                  label: this.$t('views.laughing'),
                });
                break;
              case 'sad':
                sessionReactions.push({
                  name: 'sad',
                  imageName: 'reaction-sad.png',
                  color: '#29B6E3',
                  value: reactions.sadEmojiCount,
                  fillLevel:
                    (reactions.sadEmojiCount / this.computedReactionsCount) *
                    100,
                  label: this.$t('views.crying'),
                });
                break;
              case 'claps':
                sessionReactions.push({
                  name: 'claps',
                  imageName: 'reaction-claps.png',
                  color: '#3EC8D2',
                  value: reactions.clapsCount,
                  fillLevel:
                    (reactions.clapsCount / this.computedReactionsCount) * 100,
                  label: this.$t('views.clapping'),
                });
                break;
              case 'rocket':
                sessionReactions.push({
                  name: 'rocket',
                  imageName: 'reaction-rocket.png',
                  color: '#52DBC2',
                  value: reactions.rocketCount,
                  fillLevel:
                    (reactions.rocketCount / this.computedReactionsCount) * 100,
                  label: this.$t('views.rocket'),
                });
                break;
              default:
                break;
            }
          }
          return sessionReactions;
        }
        return [
          {
            name: 'thumbs-up',
            imageName: 'reaction-thumbs-up.png',
            color: '#0B30E9',
            value: reactions.thumbsUpCount,
            fillLevel:
              (reactions.thumbsUpCount / this.computedReactionsCount) * 100,
            label: this.$t('views.thumbsUp'),
          },

          {
            name: 'funny',
            imageName: 'reaction-funny.png',
            color: '#0660F3',
            value: reactions.funEmojiCount,
            fillLevel:
              (reactions.funEmojiCount / this.computedReactionsCount) * 100,
            label: this.$t('views.laughing'),
          },
          {
            name: 'sad',
            imageName: 'reaction-sad.png',
            color: '#0092FF',
            value: reactions.sadEmojiCount,
            fillLevel:
              (reactions.sadEmojiCount / this.computedReactionsCount) * 100,
            label: this.$t('views.crying'),
          },
          {
            name: 'claps',
            imageName: 'reaction-claps.png',
            color: '#15A4F0',
            value: reactions.clapsCount,
            fillLevel:
              (reactions.clapsCount / this.computedReactionsCount) * 100,
            label: this.$t('views.clapping'),
          },
          {
            name: 'rocket',
            imageName: 'reaction-rocket.png',
            color: '#29B6E3',
            value: reactions.rocketCount,
            fillLevel:
              (reactions.rocketCount / this.computedReactionsCount) * 100,
            label: this.$t('views.rocket'),
          },
          {
            name: 'love',
            imageName: 'reaction-love.png',
            color: '#3EC8D2',
            value: reactions.heartCount,
            fillLevel:
              (reactions.heartCount / this.computedReactionsCount) * 100,
            label: this.$t('views.loveIt'),
          },
        ];
      }
      return [];
    },
    tabItems(): any {
      return [
        {
          id: 0,
          name: this.$t('views.presenter.session.statsNumberVotes'),
          isSelected: this.selectedTabItem == 0,
        },
        { id: 1, name: 'Timeline', isSelected: this.selectedTabItem == 1 },
      ];
    },
    computedShareLink(): string {
      if (
        this.currentSession &&
        this.currentSession.shareToken &&
        this.baseURL
      ) {
        const shareURL = this.baseURL + this.currentSession.shareToken;
        return shareURL;
      }
      return '';
    },
    activeMenuItems() {
      let labels = ['polls', 'reactions', 'wordclouds', 'questions'];
      if (this.currentSession && this.currentSession.deactivatedMenuItems) {
        labels = labels.filter(
          (n) => !this.currentSession.deactivatedMenuItems.includes(n)
        );
      }
      return labels;
    },
    computedStartDate(): string {
      if (this.currentSession.startAt) {
        return formatDateString(this.currentSession.startAt.toDate());
      }
      return formatDateString(null);
    },
    computedEndDate(): string {
      if (this.currentSession.endAt && !this.currentSession.sessionUnlimited) {
        return formatDateString(this.currentSession.endAt.toDate());
      }
      return formatDateString(null);
    },
    hasSessionEnded(): boolean {
      if (!this.currentSession.sessionUnlimited) {
        return (
          !this.currentSession.endAt ||
          this.now > this.currentSession.endAt.toDate()
        );
      }
      return false;
    },
  },
  methods: {
    closeSession() {
      if (this.currentSession.id) {
        if (
          confirm(this.$t('views.presenter.session.statsEndSession') as string)
        ) {
          this.$store.dispatch(
            'presenter/cancelSession',
            this.currentSession.id
          );
        }
      }
    },
    removeSession() {
      if (this.currentSession.id) {
        if (
          confirm(
            this.$t('views.presenter.session.statsDeleteSession') as string
          )
        ) {
          this.$store.dispatch('presenter/removeSession', {
            sessionId: this.currentSession.id,
            sessionPath: this.$route.path,
          });
        }
      }
    },
    editSession() {
      if (this.currentSession.id) {
        const { presenterSessionId } = this.$route.params;
        this.$router.push({
          name: 'Presenter Session Edit',
          params: { presenterSessionId },
        });
      }
    },
    openSessionLink() {
      if (this.computedShareLink) window.open(this.computedShareLink, '_blank');
    },
    onTabMenuSelect(i: number) {
      this.selectedTabItem = i;
    },
    isItemDeactivated(navItem: string): boolean {
      return (
        this.currentSession &&
        this.currentSession.deactivatedMenuItems &&
        this.currentSession.deactivatedMenuItems.includes(navItem)
      );
    },
    updateActiveItems(activeMenuItems: string[]) {
      const labels = ['polls', 'reactions', 'wordclouds', 'questions'];
      const deactivatedMenuItems = labels.filter(
        (n) => !activeMenuItems.includes(n)
      );
      this.$store.dispatch('presenter/updateDeactivatedItems', {
        deactivatedMenuItems,
      });
    },
    async downloadQRCode(): Promise<any> {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `reaction.link QR-Code Session ${this.currentSession.name}.png`;
        link.click();
        URL.revokeObjectURL(link.href);
      };
      xhr.open('GET', this.currentSession.qrCodeImageURL);
      xhr.send();
    },

    showCopySuccessToast(): void {
      Vue.$toast.open({
        message: this.$t('views.presenter.session.statsSuccessToast') as string,
        type: 'success',
        position: 'top-right',
      });
    },

    showCopyFailureToast(): void {
      Vue.$toast.open({
        message: this.$t('views.presenter.session.statsFailureToast') as string,
        type: 'error',
        position: 'top-right',
      });
    },
    openEmbedModal() {
      this.showEmbedModal = true;
    },
    closeEmbedModal() {
      this.showEmbedModal = false;
    },
  },
});
