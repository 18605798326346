





















































































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'EmbedModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      name: '',
    };
  },
  computed: {
    computedEmbedCode(): string {
      return `<iframe src="${
        'https://' + window.location.host
      }/viewer/sessions/${
        this.$route.params.presenterSessionId
      }" loading="lazy" height="100%" width="100%" style="min-height: 500px; min-width: 350px" frameBorder="0"/>`;
    },
  },
  methods: {
    showCopyToast(): void {
      Vue.$toast.open({
        message: this.$t(
          'components.presenter.embedModalToastMessage'
        ) as string,
        type: 'success',
        position: 'top-right',
      });
    },
  },
});
